<template>
  <div class="project_space"
    v-loading="loading"
    element-loading-background="rgba(24,34,45,0.9)">
    <vue-scroll>
      <div class="project padding-top-30">
        <section-title class="margin-left-10 margin-bottom-20" title="项目"></section-title>
        <div class="project_list">
          <div class="item"
            v-for="(item) in projectList"
            :key="item.id">
            <div class="item_cover" @click="detail(item, 'project')">
              <img :src="item.cover" width="220" height="160" v-if="item.cover" />
              <div class="item_status">
                <p class="text_size_12 text_color_primary">{{ item.created_at }}</p>
              </div>
            </div>
            <div class="item_footer">
              <p class="item_footer_name text_size_14 text_color_white" :title="item.title">{{item.title}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="space padding-top-20">
        <section-title class="margin-left-10 margin-bottom-20" title="空间"></section-title>
        <div class="space_list">
          <div class="item"
            v-for="(item) in spaceList"
            :key="item.id">
            <div class="item_cover" @click="detail(item, 'space')">
              <img :src="item.cover" width="220" height="160" v-if="item.cover" />
              <div class="item_status">
                <p class="text_size_12 text_color_primary">{{ item.created_at }}</p>
              </div>
            </div>
            <div class="item_footer">
              <p class="item_footer_name text_size_14 text_color_white" :title="item.title">{{item.title}}</p>
            </div>
          </div>
        </div>
      </div>
    </vue-scroll>
  </div>
</template>

<script>
import * as api from '~/api'
export default {
  data () {
    return {
      loading: false,
      projectList: [],
      spaceList: []
    }
  },
  created () {
    // 获取空间及项目列表
    this.getProjectSpaceList()
  },
  methods: {
    // 获取项目及个人空间列表
    getProjectSpaceList (result) {
      this.loading = true
      api.getProjectSpaceList().then(res => {
        if (res.data.code === 0) {
          // console.log('项目及空间列表', res.data.data)
          this.projectList = res.data.data.project_spaces
          this.spaceList = res.data.data.private_spaces
        } else {
          this.$message.error(res.data.message)
        }
        this.loading = false
      }).catch(err => {
        this.loading = false
        console.log(err)
        this.$message.error('网络错误，请重试！')
      })
    },
    // 进入项目的详情
    detail (item, type) {
      this.$router.push({
        path: '/project-detail',
        query: {
          projectName: encodeURIComponent(item.title),
          projectId: item.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .project_space {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0px 30px;
    .project, .space {
      .project_list, .space_list {
        display: flex;
        flex-wrap: wrap;
        .item {
          width:240px;
          height:220px;
          background:$black-color-light-1;
          border-radius:6px;
          margin: 0px 10px 20px 10px;
          padding: 10px 10px 0 10px;
          &:hover {
            background: $black-color-light-3;
          }
          &_cover {
            width: 220px;
            height: 160px;
            // background-color: #1A3446;
            background-color: $black-color-light-2;
            background-repeat: no-repeat;
            background-image: url('../../../assets/img/project-manage/item_bg_big.png');
            background-position: center;
            background-size: 50%;
            cursor: pointer;
            position: relative;
            object-fit: cover;
          }
          &_status {
            position: absolute;
            right: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100px;
            height: 24px;
            background:rgba(27,27,27,1);
            opacity: 0.5;
            border-radius:2px 0px 2px 0px;
          }
          &_footer {
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &_name {
              flex: 1;
              text-align: center;
              padding: 0 10px;
              user-select: none;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
</style>
